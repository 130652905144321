import React from "react"
import styled from "styled-components"
import GloriaInfo from "./gloriaInfo"
import GoogleMapComponentWithMarker from "./map"
import { Paper, Container, ThemeProvider } from "@material-ui/core"
import { secondaryTheme } from "../utils/theme"

const Footer = (props) => {
  return (
    <ThemeProvider theme={secondaryTheme}>
      <Paper elevation={4} square style={{ background: "#a90000" }}>
        <Container maxWidth="md">
          <GloriaInfo>
            <GoogleMapComponentWithMarker
              googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyCrw3DozzgAmMcO1Ol6VhdGZ6bmKmsZs70"
              loadingElement={
                <div
                  style={{
                    height: `100%`,
                    width: "100%",
                    background: "rgba(0,0,0,.7",
                  }}
                />
              }
              containerElement={
                <div style={{ height: `100%`, width: "100%" }} />
              }
              mapElement={<div style={{ height: `100%`, width: "100%" }} />}
              zoom={16}
              url={"https://goo.gl/maps/EuAP9P5S3eW8irL68"}
              location={{
                lat: 45.8982545, // latitude to position the marker
                lng: 16.844672, // longitude to position the marker
              }}
            />
          </GloriaInfo>
        </Container>
      </Paper>
    </ThemeProvider>
  )
}

export default Footer
