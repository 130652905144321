import React from "react"
import styled from "styled-components"

import logoWhite from "../images/gloria/gloria-logo-white.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faLocationArrow,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons"
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
} from "@material-ui/core"
import { Link } from "gatsby-theme-material-ui"

const infoRows = [
  {
    name: "location",
    icon: faLocationArrow,
    info: "J.J.Strossmayera 9, Bjelovar",
  },
  {
    name: "phone",
    icon: faPhone,
    info: "043/241-242",
    href: "tel:0038543251242",
  },
  {
    name: "mail",
    icon: faEnvelope,
    info: "gloria@bj.t-com.hr",
    href: "mailto:gloria@bj.t-com.hr",
  },
]

const workHours = [
  { days: "Pon-Čet:", time: "8-12h i 17-21h" },
  { days: "Pet:", time: "8-12h" },
]

const GloriaInfo = (props) => {
  return (
    <Grid container component={Box} spacing={1} sm={12} p={2}>
      <Grid item container sm={12} md={8} spacing={1}>
        <Grid item sm={6}>
          <Typography variation="h3">Kontakt:</Typography>
          <TableContainer style={{ width: "100%" }}>
            <Table>
              <TableBody>
                {infoRows.map(({ name: key, icon, href, info }) => {
                  let rowProps = { key }
                  if (href) {
                    rowProps.href = href
                    rowProps.component = Link
                  }
                  return (
                    <TableRow {...rowProps}>
                      <TableCell align="right">
                        {<FontAwesomeIcon icon={icon} />}
                      </TableCell>
                      <TableCell align="left">{info}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item sm={6}>
          <Typography variation="h3">Radno vrijeme:</Typography>
          <TableContainer style={{ width: "100%" }}>
            <Table>
              <TableBody>
                {workHours.map(({ days, time }) => (
                  <TableRow>
                    <TableCell align="right">{days}</TableCell>
                    <TableCell align="left">{time}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12} component={Box} py={8}>
          <Typography align="center" variation="subtitle1">
            Povoljno i brzo prevođenje svih vrsta dokumenata sa i na strane
            jezike. Iskustvo više od 25 godina.
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        sm={12}
        md={3}
        style={{ minHeight: "300px", minWidth: "300px" }}
      >
        {props.children}
      </Grid>
    </Grid>
  )
}

export default GloriaInfo
