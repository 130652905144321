import React, {useMemo} from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Img from 'gatsby-image/withIEPolyfill'
import {useWindowSize} from '../hooks/useWindowSize'

import Footer from "./footer"
import Nav from "./nav"
import Seo from "./seo"

import { Container, ThemeProvider, Paper, Box } from "@material-ui/core"
import { mainTheme } from "../utils/theme"

const Main = styled.main`
  margin: auto;
  position: relative;
  min-height: 1000px;
  height: auto;
  max-width: 900px;
  width: 100%;
`

const Layout = ({ children,titleImage,fixed,title }) => {
  
  const size = useWindowSize();
  
  const isMobile = useMemo(()=>size?.width <= 600,[size])
  return (
    <>
      <Seo title={title} />
      <ThemeProvider theme={mainTheme}>
        <Nav theme={{ position: "relative" }} fixed={isMobile || fixed} />
        
        <Container maxWidth="md" component={Paper} square elevation={1} py={0} style={{minHeight:'70vh'}}>
        {titleImage &&  <Img
        fluid={titleImage.childImageSharp.fluid}
      />}
          <Box py={6}>{children}</Box>
        </Container>
      </ThemeProvider>
      <Footer />
    </>
  )
}


Layout.propTypes = {
  children: PropTypes.node.isRequired,
  titleImage: PropTypes.string,
  fixed: PropTypes.bool,
  title: PropTypes.string

}

export default Layout
